export default function () {
  $('video').each((index, vid) => {
    const video = vid;

    const $btnPlay = $(`.btn-video.play[data-target="#${video.id}"]`);
    const $btnMute = $(`.btn-video.muted[data-target="#${video.id}"]`);

    // show UI
    const fallback = () => {
      $btnPlay.css('display', 'block');

      video.muted = false;
      $btnMute.addClass('off');
    };

    const toggleAudio = (e) => {
      e.preventDefault();
      e.stopPropagation();

      $btnMute.toggleClass('off');
      video.muted = !video.muted;
    };

    // Autoplay
    if (video.autoplay) {
      const promise = video.play();

      if (promise !== undefined) {
        promise.catch(fallback).then(() => {
          $btnPlay.remove();
        });
      } else {
        fallback('Promise undefined');
      }
    } else {
      fallback('Without autoplay');
    }

    $btnPlay.on('click', (e) => {
      e.preventDefault();
      const $this = $(e.currentTarget);
      const currentVideo = $($this.data('target'))[0];

      $this.remove();
      currentVideo.play();
    });

    $btnMute.on('click', toggleAudio);
    $('.video').on('click', toggleAudio);
  });
}
