import 'slick-carousel';
import createScenes from './_createScenes';

import homeVideo from './_video';

import strawberry1 from '../images/floating/tr.png';
import strawberry2 from '../images/floating/tr2.png';
import cherry1 from '../images/floating/cherry.png';
import cherry2 from '../images/floating/cherry2.png';
import cranberry1 from '../images/floating/cranberry.png';
import cranberry2 from '../images/floating/cranberry2.png';
import cranberry3 from '../images/floating/cranberry3.png';
import blueberry1 from '../images/floating/blueberry.png';
import blueberry2 from '../images/floating/blueberry2.png';

homeVideo();

const fruits = [
  {
    name: 'strawberry-1',
    target: '#products',
    image: strawberry1,
    velocity: 1.2,
    friction: 0.4,
    style: {
      position: 'absolute',
      left: 0,
      top: 0,
      'z-index': 1,
    },
  },
  {
    name: 'strawberry-2',
    target: '#products',
    image: strawberry2,
    velocity: 0.4,
    style: {
      position: 'absolute',
      left: '-8rem',
      top: '-5rem',
      'z-index': 0,
    },
  },
  {
    name: 'cherry-1',
    target: '#products',
    image: cherry1,
    velocity: 0.7,
    scalar: 40,
    style: {
      position: 'absolute',
      right: '-8rem',
      top: '1rem',
      'z-index': 0,
    },
  },
  {
    name: 'cherry-2',
    target: '#products',
    image: cherry2,
    velocity: 0.2,
    style: {
      position: 'absolute',
      right: '-2rem',
      top: '-5rem',
      'z-index': 0,
    },
  },
  {
    name: 'cranberry-1',
    target: '#news',
    image: cranberry1,
    velocity: 5,
    style: {
      position: 'absolute',
      left: '-15%',
      top: '4rem',
      'z-index': 1,
      width: '60px',
    },
  },
  {
    name: 'cranberry-2',
    target: '#news',
    image: cranberry2,
    velocity: 1.5,
    style: {
      position: 'absolute',
      left: '-5%',
      top: '6rem',
      'z-index': 0,
      width: '40px',
    },
  },
  {
    name: 'cranberry-3',
    target: '#news',
    image: cranberry2,
    velocity: 0.8,
    style: {
      position: 'absolute',
      left: '55%',
      top: '12rem',
      'z-index': 0,
      width: '35px',
    },
  },
  {
    name: 'cranberry-4',
    target: '#news',
    image: cranberry3,
    velocity: 1,
    friction: 0.075,
    style: {
      position: 'absolute',
      right: '-2rem',
      top: '4rem',
      'z-index': 0,
      width: '80px',
    },
  },
  {
    name: 'blueberry-1',
    target: '#news',
    image: blueberry1,
    velocity: 0.8,
    style: {
      position: 'absolute',
      left: '-12rem',
      bottom: '6rem',
      'z-index': 1,
      width: '60px',
    },
  },
  {
    name: 'blueberry-2',
    target: '#news',
    image: blueberry2,
    velocity: 0.3,
    friction: 0.075,
    style: {
      position: 'absolute',
      left: '-14rem',
      bottom: '4rem',
      'z-index': 0,
      width: '50px',
    },
  },
];

createScenes(fruits);

// NEWS CAROUSEL
$('.js-news').on('beforeChange', (event, slick, currentSlide, nextSlide) => {
  const $images = $('.home-news-image > img');

  $images.removeClass('is-current');
  $images.eq(nextSlide).addClass('is-current');
});

$('.js-news').slick({
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  fade: true,
  prevArrow: '#news-prev',
  nextArrow: '#news-next',
});

// RECIPES CAROUSEL
const $window = $(window);
const $carousel = $('.js-recipes');
const carouselBreakpoint = 768;
const slickSettings = {
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  fade: true,
  prevArrow: '#recipes-prev',
  nextArrow: '#recipes-next',
  mobileFirst: true,
};

if ($window.width() < carouselBreakpoint) {
  $carousel.slick(slickSettings);
}

$window.on('resize orientationchange', () => {
  const winWidth = $window.width();

  if (winWidth >= carouselBreakpoint && $carousel.hasClass('slick-initialized')) {
    $carousel.slick('unslick');
  }

  if (winWidth < carouselBreakpoint && !$carousel.hasClass('slick-initialized')) {
    $carousel.slick(slickSettings);
  }
});
