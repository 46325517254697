export default function (fruits) {
  const scenes = [];

  fruits.forEach((fruit) => {
    const $target = $(fruit.target);
    const $scene = $(`<div id="${fruit.name}" class="js-parallax-scene" />`).css(fruit.style);
    const $img = $('<img class="js-parallax" />');

    $img.prop({
      src: fruit.image,
      alt: fruit.name,
    });

    if (fruit.velocity) {
      $img.attr('data-depth', fruit.velocity);
    }

    if (fruit.friction) {
      $img.attr({
        'data-friction-x': parseFloat(fruit.friction),
        'data-friction-y': parseFloat(fruit.friction),
      });
    }

    if (fruit.scalar) {
      $img.attr({
        'data-scalar-x': parseFloat(fruit.scalar),
        'data-scalar-y': parseFloat(fruit.scalar),
      });
    }

    $target.append($scene);
    $scene.append($img);

    scenes.push($scene);
  });
}
